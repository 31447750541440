import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { Card } from '@fremtind/jkl-card-react';
import { Loader } from '@fremtind/jkl-loader-react';
import { TextInput } from '@fremtind/jkl-text-input-react';
import { ToggleSlider } from '@fremtind/jkl-toggle-switch-react';

import { toCurrency, toCurrencyRate } from './util';

import './App.scss';

const fetcher = (...args) => fetch(...args).then((res) => res.json());

function App() {
  const [rate, setRate] = useState();
  const [amount, setAmount] = useState();
  const [convertedAmount, setConvertedAmount] = useState();
  const [targetCurrency, setTargetCurrency] = useState('kr');

  const { data } = useSWR(
    'https://data.norges-bank.no/api/data/EXR/B.EUR.NOK.SP?format=sdmx-json&lastNObservations=1&locale=no',
    fetcher
  );

  useEffect(() => {
    setRate(toCurrencyRate(data));
  }, [data]);

  useEffect(() => {
    setConvertedAmount(toCurrency(targetCurrency, amount, rate));
  }, [amount, rate, targetCurrency]);

  if (!data) {
    return <Loader />;
  }

  return (
    <div className="app">
      <h1 className="jkl-title-large jkl-component-spacing--medium-bottom">
        Umtauschkurs
      </h1>
      <Card className="jkl-lead jkl-component-spacing--xxl-bottom">
        1€ = {rate} kr
      </Card>
      <div className="jkl-component-spacing--medium-bottom">
        <ToggleSlider
          className="jkl-component-spacing--medium-bottom"
          defaultValue="EUR"
          labels={['EUR', 'NOK']}
          onToggle={() => {
            setTargetCurrency(targetCurrency === 'kr' ? '€' : 'kr');
            setConvertedAmount(toCurrency(targetCurrency, amount, rate));
          }}
        >
          Umtausch von
        </ToggleSlider>
        <TextInput
          inline
          className="jkl-component-spacing--medium-bottom"
          variant="small"
          onChange={(e) => setAmount(e.target.value)}
        />
      </div>
      {!isNaN(convertedAmount) && (
        <p className="jkl-heading-medium">
          {`${convertedAmount} ${targetCurrency}`}
        </p>
      )}
    </div>
  );
}

export default App;
