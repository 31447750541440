export const Currency = {
  NOK: 'kr',
  EUR: '€',
};

export const toCurrencyRate = (data) =>
  data?.data?.dataSets[0]?.series['0:0:0:0']?.observations[0][0];

export const toCurrency = (targetCurrency, amount, rate) => {
  if (targetCurrency === Currency.EUR) return (amount / rate).toFixed(2);
  return (amount * rate).toFixed(2);
};
